import React from 'react'
import axios from 'axios'
import './MemberPopup.css'
import { API, FILE_SERVER_URL } from '../../../constant'
import { Link } from 'react-router-dom'
const EditMemberPopup = ({ memberData, onClose, isOpen, setMemberData, updateMemberShip }) => {



    const onChangeForm = (e) => {
        const { name, value } = e.target;
        setMemberData(p => {
            return {
                ...p,
                [name]: value
            }
        })
    }

    return (
        <div className={`popup-container-backdrop ${isOpen ? "open" : ""}`}>

            <div className="popup-container">
                <div className="adminCard member-left">
                    <div className="member-img AdminMembershipTableImg">
                        <img src={`${FILE_SERVER_URL}/${memberData?.candidate_photo}`} />
                    </div>
                    <div className="descLine CInputGroup">
                        <b>Name:</b>
                        <input className="" type="text" value={memberData?.full_name} name='full_name' onChange={onChangeForm} />
                    </div>
                    <div className="descLine CInputGroup">
                        <b>Date Of Birth:</b>
                        <input className="" type="text" value={memberData?.dob} name='dob' onChange={onChangeForm} />
                    </div>
                    <div className="descLine CInputGroup">
                        <b>Gender:</b>
                        <input className="" type="text" value={memberData?.gender} name='gender' onChange={onChangeForm} />
                    </div>
                    <div className="descLine CInputGroup">
                        <b>Contact No:</b>
                        <input className="" type="text" value={memberData?.contact_no} name='contact_no' onChange={onChangeForm} />
                    </div>
                    <div className="descLine CInputGroup">
                        <b>Address:</b>
                        <input className="" type="text" value={memberData?.address} name='address' onChange={onChangeForm} />
                    </div>

                    <div className="descLine CInputGroup">
                        <b>Email:</b>
                        <input className="" type="text" value={memberData?.email} name='email' onChange={onChangeForm} />
                    </div>
                    <div className="descLine CInputGroup">
                        <b>Reference:</b>
                        <input className="" type="text" value={memberData?.reference} name='reference' onChange={onChangeForm} />
                    </div>
                    <div className="descLine CInputGroup">
                        <b>District:</b>
                        <input className="" type="text" value={memberData?.district} name='district' onChange={onChangeForm} />
                    </div>
                    <div className="descLine CInputGroup">
                        <b>Pin Code:</b>
                        <input className="" type="text" value={memberData?.pincode} name='pincode' onChange={onChangeForm} />
                    </div>
                    <div className="descLine CInputGroup">
                        <b>Aadhar Card No:</b>
                        <input className="" type="text" value={memberData?.aadhar_no} name='aadhar_no' onChange={onChangeForm} />
                    </div>

                    <div className="descLine  flexRow">
                        <b>Aadharcard Pdf:</b>
                        <Link target='_blank' to={`${FILE_SERVER_URL}/${memberData?.aadhar_pdf_url}`} className='AMFLink sm'>View</Link>
                    </div>
                    <div className="descLine  flexRow">
                        <b>Police Verification:</b>
                        <Link target='_blank' to={`${FILE_SERVER_URL}/${memberData?.police_verification_url}`} className='AMFLink sm'>View</Link>
                    </div>
                    {
                        memberData?.id_card_no && memberData?.id_card_path &&
                        <div className="descLine  flexRow">
                            <b>ID Card:</b>
                            <Link target='_blank' to={`${FILE_SERVER_URL}/${memberData?.id_card_path?.url}`} className='AMFLink sm'>View</Link>
                        </div>
                    }
                </div>
                <div className="memberPopupBtns">
                    <button className='AMFButton md' onClick={onClose}>Close</button>
                    <button className='AMFButton md' onClick={updateMemberShip}>Update</button>
                </div>
            </div>
        </div>
    )
}

export default EditMemberPopup