import React from 'react'
import userImg from '../../../assets/userLogo.png'
import './Sidebar.css'
import { Link } from 'react-router-dom'

//  Icons For Dashbord
import { MdDashboard, MdVolunteerActivism, MdCardMembership, MdLogout } from "react-icons/md";
import { TfiLayoutSliderAlt } from "react-icons/tfi";
import { RiTeamFill } from "react-icons/ri";
import { FaYoutube } from "react-icons/fa";
import { GrGallery } from "react-icons/gr";
const Sidebar = () => {

  const logout = () => {
    localStorage.removeItem("token");
    window.location.href = "/admin";
  }

  return (
    <div className='admin-sidebar'>
      <div className='AdminSidebarBrand'>
        <div className='AdminSidebarBrandImg'>
          <img src={userImg} alt="" />
        </div>
        <h2>Name</h2>
      </div>
      <div className="admin-sidemenu">
        <ul className='admin-sidemenu-links'>
          <li><Link to="/admin/dashboard"><MdDashboard /> Dashborad</Link></li>
          <li><Link to="/admin/membership"><MdVolunteerActivism /> MemberShip</Link></li>
          <li><Link to="/admin/letestactivity">< MdCardMembership /> Latest Actvity</Link></li>
          <li><Link to="/admin/Slider"><TfiLayoutSliderAlt /> Slider</Link></li>
          <li><Link to="/admin/gallery"><GrGallery /> Gallery</Link></li>
          <li><Link to="/admin/team"><RiTeamFill /> Team</Link></li>
          <li><Link to="/admin/youtube"><FaYoutube /> Youtube</Link></li>
          <li><Link to="/admin/files"><FaYoutube /> Files</Link></li>
          <li><button to="/admin/dashboard" onClick={() => logout()}><MdLogout /> Logout</button></li>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar